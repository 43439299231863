import React from 'react';
import UserContext from '../../context/UserContext';

// This function takes a component...
export default function withUserContext(Component) {
  // ...and returns another component...
  return function ContextedComponent(props) {
    // ... and renders the wrapped component with the context theme!
    // Notice that we pass through any additional props as well
    return (
      <UserContext.Consumer>
        {userContext => <Component {...props} userContext={userContext} />}
      </UserContext.Consumer>
    );
  };
}
