import React from 'react';
import { withStyles, Button } from '@material-ui/core';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Icon from './Icon';

const styles = theme => ({
  fbButton: {
    alignItems: 'center',
    backgroundColor: '#3B5998',
    '&:hover': {
      backgroundColor: '#6d84b4'
    },
    color: theme.palette.primary.contrastText
  },
  icon: {
    paddingRight: `${theme.spacing.unit * 3}px`
  }
});

const FacebookButton = props => {
  const { classes } = props;
  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_ID}
      autoLoad={false}
      fields="id,name,email,picture"
      callback={props.responseFacebook}
      onClick={props.onClick}
      disableMobileRedirect
      render={renderProps => (
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={classes.fbButton}
          onClick={renderProps.onClick}
        >
          <Icon slug="facebook" color="white" />
          {props.text ? props.text : 'Ingresar con Facebook'}
        </Button>
      )}
    />
  );
};

export default withStyles(styles)(FacebookButton);
