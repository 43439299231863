import React, { Component } from 'react';
import Header from '../components/Header/Header';

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import withUserContext from './hoc/withUserContext';
import ErrorBoundary from './common/ErrorBoundary';
import AsyncComponent from './common/AsyncComponent';
import GoogleAnalytics from './GoogleAnalytics';

const AsyncHome = AsyncComponent(() => import('./pages/Home/Home'));
const AsyncEstablishmentProfile = AsyncComponent(() =>
  import('./pages/EstablishmentProfile/EstablishmentProfile')
);
const AsyncBookingSummary = AsyncComponent(() =>
  import('./pages/BookingSummary')
);
const AsyncFavorites = AsyncComponent(() => import('./pages/Favorites'));
const AsyncBookingShow = AsyncComponent(() => import('./pages/BookingShow'));
const AsyncProfile = AsyncComponent(() => import('./pages/Profile'));
const AsyncMyBookings = AsyncComponent(() => import('./pages/MyBookings.js'));
const AsyncSingIn = AsyncComponent(() => import('./pages/SignIn/SignIn'));
const AsyncSearchResults = AsyncComponent(() =>
  import('./pages/SearchResults/SearchResults')
);
const AsyncCardRegistration = AsyncComponent(() =>
  import('./pages/CardRegistration')
);
const AsyncCardRegistrationReturn = AsyncComponent(() =>
  import('./pages/CardRegistrationReturn')
);

const AsyncGeneralError = AsyncComponent(() =>
  import('./pages/ErrorPages/500')
);
const AsyncNotFound = AsyncComponent(() => import('./pages/ErrorPages/404'));

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return isLoggedIn ? (
        <ErrorBoundary {...props}>
          <Component {...props} />
        </ErrorBoundary>
      ) : (
        <Redirect
          to={{
            // pathname: `/entrar?intent=${props.location.pathname}`,
            pathname: `/entrar`,
            state: { from: props.location }
          }}
        />
      );
    }}
  />
);
class AppRouter extends Component {
  render() {
    const { userContext } = this.props;
    return (
      <React.Fragment>
        {userContext.userReady && (
          <Router>
            <div>
              {this.props.children}
              <Header {...this.props} />
              <Switch>
                <Route
                  exact
                  path="/"
                  component={props => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncHome userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />
                <Route
                  exact
                  path="/entrar"
                  component={props => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncSingIn userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />
                <Route
                  exact
                  path="/locales/:id"
                  component={props => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncEstablishmentProfile {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />

                <Route
                  exact
                  path="/buscar/"
                  component={props => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncSearchResults
                          userContext={userContext}
                          {...props}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />

                <Route
                  exact
                  path="/pagos/registrar"
                  component={props => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncCardRegistration
                          userContext={userContext}
                          {...props}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />

                <Route
                  exact
                  path="/pagos/bancard-retorno"
                  component={props => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncCardRegistrationReturn
                          userContext={userContext}
                          {...props}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />

                <Route
                  exact
                  path="/canchas"
                  component={props => {
                    window.location = `https://landing.revaapp.co/canchas`;
                    return (
                      <Redirect
                        to={{
                          pathname: `/`,
                          state: { from: props.location }
                        }}
                      />
                    );
                  }}
                />

                {/* Restricted Routes */}
                <RestrictedRoute
                  path="/mis-reservas"
                  isLoggedIn={userContext.loggedIn}
                  component={props => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncMyBookings userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />
                <RestrictedRoute
                  exact
                  path="/favoritos"
                  isLoggedIn={userContext.loggedIn}
                  component={props => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncFavorites userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />
                <RestrictedRoute
                  exact
                  path="/perfil"
                  isLoggedIn={userContext.loggedIn}
                  component={props => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncProfile userContext={userContext} {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />
                <RestrictedRoute
                  exact
                  path="/reserva/:id"
                  isLoggedIn={userContext.loggedIn}
                  component={props => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncBookingShow
                          userContext={userContext}
                          {...props}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />
                <RestrictedRoute
                  exact
                  path="/locales/:id/reservar"
                  isLoggedIn={userContext.loggedIn}
                  component={props => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncBookingSummary
                          userContext={userContext}
                          {...props}
                        />
                      </ErrorBoundary>
                    );
                  }}
                />
                <Route
                  exact
                  path="/500"
                  component={props => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncGeneralError />
                      </ErrorBoundary>
                    );
                  }}
                />
                <Route
                  component={props => {
                    return (
                      <ErrorBoundary {...props}>
                        <AsyncNotFound />
                      </ErrorBoundary>
                    );
                  }}
                />
              </Switch>
              <GoogleAnalytics />
            </div>
          </Router>
        )}
      </React.Fragment>
    );
  }
}
export default withUserContext(AppRouter);
