import ReactGA from 'react-ga';
import React, { Component } from 'react';
import AppRouter from './components/AppRouter';
import ThemeProvider from './providers/ThemeProvider';
import UserContext from './context/UserContext';
import { getUser } from './network/user';
import { CssBaseline } from '@material-ui/core';
import SmartBanner from 'react-smartbanner';
import '../node_modules/react-smartbanner/dist/main.css';

function initializeReactGA() {
  if (process.env.REACT_APP_ENV === 'production') {
    ReactGA.initialize('UA-136500414-1');
    ReactGA.pageview('/');
  }
}
class App extends Component {
  constructor(props) {
    super(props);
    this.changeUser = (user, token, callback) => {
      const newState = {
        user: user,
        loggedIn: user ? true : false
      };
      if (token) {
        localStorage.setItem('jwtToken', token);
        newState.jwtToken = token;
      }
      this.setState(newState);
    };

    this.state = {
      user: null,
      jwtToken: localStorage.getItem('jwtToken')
        ? localStorage.getItem('jwtToken')
        : null,
      changeUser: this.changeUser
    };
  }

  componentDidMount() {
    initializeReactGA();
    let user = null;
    const storageToken = localStorage.getItem('jwtToken');
    if (storageToken) {
      // Fetch User
      getUser()
        .then(response => {
          user = response.data.data;
          this.setState(state => {
            return { ...state, user: user, userReady: true, loggedIn: true };
          });
        })
        .catch(error => {
          this.setState(state => {
            return { user: null, userReady: true, loggedIn: false };
          });
        });
    } else {
      this.setState(state => {
        return { user: null, userReady: true, loggedIn: false };
      });
    }
  }

  onInstallClick() {
    console.log('clicked');
    ReactGA.event({
      category: 'App',
      action: 'Clicked Download Smart Banner'
    });
  }
  onCloseBanner() {
    console.log('closed');
    ReactGA.event({
      category: 'App',
      action: 'Closed Smart Banner'
    });
  }

  render() {
    return (
      <div>
        <SmartBanner
          title={'Reva'}
          daysReminder={1}
          daysHidden={1}
          button="Ver"
          onInstall={this.onInstallClick}
          price={{
            ios: 'FREE',
            android: 'FREE',
            windows: 'FREE',
            kindle: 'FREE'
          }}
          onClose={this.onCloseBanner}
        />
        <ThemeProvider>
          <CssBaseline />

          <UserContext.Provider value={this.state}>
            <AppRouter />
          </UserContext.Provider>
        </ThemeProvider>
      </div>
    );
  }
}
export default App;
