import React from 'react';
import { getUserRoute, logoutRoute } from './api-routes';
import performRequest from './perform-request';
import { Redirect } from 'react-router-dom';

export const getUser = () => {
  return performRequest('GET', getUserRoute, null, true);
};

export const logoutUser = changeUser => {
  performRequest('GET', logoutRoute, null, true)
    .then(() => {
      localStorage.setItem('jwtToken', null);
      changeUser(null, null);
      return <Redirect to="/" />;
    })
    .catch(error => {
      console.log('Logout failed', error);
    });

  return;
};
