export const baseUrl = process.env.REACT_APP_BASE_URL;
export const imgServerBaseUrl = process.env.REACT_APP_IMG_SERVER;

// USER
export const getUserRoute = '/users/show';
export const refreshRoute = '/refresh-token/';
export const signInRoute = '/login/';
export const registerRoute = '/register/';
export const logoutRoute = '/logout/';
export const facebookLoginRoute = '/login/facebook/';

export const featuredIndexRoute = '/featured/index';
export const getEstablishmentRoute = '/public/establishments/show';
export const establishmentAvailableHoursRoute =
  '/establishment/available-hours';
export const groupEstablishmentsIndexRoute = '/establishments/grouped-index';

export const startPhoneVerificationRoute = '/phone-verification/start';
export const verifyPhoneCodeRoute = '/phone-verification/verify-code';

// BOOKINGS
export const bookingSummaryRoute = '/bookings/summary/';
export const createBookingRoute = '/bookings/create/';
export const activeBookingsRoute = '/bookings/all-active/';
export const cancelBookingRoute = '/bookings/cancel/';
export const showBookingRoute = '/bookings/show';
// SEARCH
export const searchByFiltersRoute = '/app/search/';

// FAVORITE
export const favoriteEstablishmentRoute = '/establishments/favorite/';
export const favoriteEstablishmentsIndexRoute =
  '/establishments/user-favorites/';
