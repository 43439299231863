import React from 'react';
import { Grid, Typography, Avatar, Menu, MenuItem } from '@material-ui/core';

import { imgServerBaseUrl } from '../../network/api-routes';
import { isAbsoluteUrl } from '../../network/helpers';

class UserAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: true,
      anchorEl: null
    };
  }

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { userContext, classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const user = userContext.user;
    return (
      <Grid item className={classes.cursorPointer}>
        {user.profile_pic ? (
          <Avatar
            alt="avatar"
            onClick={this.handleMenu}
            src={
              isAbsoluteUrl(user.profile_pic)
                ? user.profile_pic
                : `${imgServerBaseUrl}/${user.profile_pic}`
            }
          />
        ) : (
          <Typography color="inherit" onClick={this.handleMenu}>
            {`${user.first_name} ${user.last_name}`}
          </Typography>
        )}
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={open}
          onClose={this.handleClose}
        >
          {/* <MenuItem onClick={this.handleClose}>
            <Link to="/perfil">Mi perfil</Link>
          </MenuItem> */}
          <MenuItem onClick={() => this.props.handleLogoutClick(userContext)}>
            Salir
          </MenuItem>
        </Menu>
      </Grid>
    );
  }
}

export default UserAvatar;
