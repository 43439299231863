import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  signInRoute,
  facebookLoginRoute,
  baseUrl
} from '../../../network/api-routes';
import UserContext from '../../../context/UserContext';
import FacebookButton from '../../common/FacebookButton';
import { Typography } from '@material-ui/core';
import axios from 'axios';

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  errorMessage: {
    maxWidth: '100%',
    color: theme.palette.primary.textError
  }
});

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: false,
      errorMessage: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
  }

  handleSubmit(event, changeUser) {
    event.preventDefault();

    axios
      .post(`${baseUrl}${signInRoute}`, {
        email: this.state.email,
        password: this.state.password,
        web_app: true
      })
      .then(response => {
        const user = response.data.data;
        const token = response.data.meta.token;
        changeUser(user, token);
        this.props.successCallback();
      })
      .catch(error => {
        let errorMessage = 'Parece que tus datos no son correctos';

        this.setState({
          error: true,
          errorMessage: errorMessage
        });
      });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  responseFacebook(response, changeUser) {
    console.log(response);
    //Log in user with token
    axios
      .post(`${baseUrl}${facebookLoginRoute}`, {
        fbtoken: response.accessToken
      })

      .then(response => {
        const user = response.data.data;
        const token = response.data.meta.token;
        changeUser(user, token);
        this.props.successCallback();
      })
      .catch(error => {
        this.setState({
          error: true,
          errorMessage: 'Ha ocurrido un error al intentar ingresar con Facebook'
        });
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <UserContext.Consumer>
        {userContext => (
          <div className={classes.container}>
            <FacebookButton
              responseFacebook={response => {
                this.responseFacebook(response, userContext.changeUser);
              }}
            />
            <br />
            <Typography variant="caption">o también</Typography>
            <form
              onSubmit={event => {
                this.handleSubmit(event, userContext.changeUser);
              }}
              className={classes.form}
            >
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  id="email"
                  name="email"
                  autoComplete="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Contraseña</InputLabel>
                <Input
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                />
              </FormControl>
              {this.state.error && (
                <div className={classes.errorMessage}>
                  <Typography color="inherit">
                    {this.state.errorMessage}
                  </Typography>
                </div>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Ingresar
              </Button>
            </form>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SignIn);
