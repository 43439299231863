import React from 'react';
import { Grid, Typography, Hidden, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import UserAvatar from './UserAvatar';

const styles = theme => ({
  cursorPointer: {
    cursor: 'pointer'
  },
  linksMdContainer: {
    padding: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 4
  },
  link: {
    fontSize: 'inherit',
    fontWeight: 'inherit'
  }
});

const LinksTopBar = props => {
  const { classes, userContext, links, hideRegister } = props;
  const ready = userContext.userReady;
  if (!ready) {
    return null;
  }
  const user = userContext.user;
  console.log('links: ', props);
  return (
    <Hidden only={['xs', 'sm']}>
      <Grid
        container
        spacing={40}
        justify="flex-end"
        alignItems="center"
        className={classes.linksMdContainer}
      >
        {links(null).map(link => {
          if ((!user && link.auth) || (user && !link.auth)) {
            return null;
          }
          return (
            <Grid item key={link.url}>
              <Link to={link.url}>
                <Typography color="inherit" className={classes.link}>
                  {link.title}
                </Typography>
              </Link>
            </Grid>
          );
        })}
        {!user && (
          <Grid
            item
            className={classes.cursorPointer}
            onClick={() => {
              props.handleSignInOpen(true);
            }}
          >
            <Typography color="inherit" className={classes.link}>
              Iniciar sesión
            </Typography>
          </Grid>
        )}
        {!user &&
          !hideRegister && (
            <Grid
              item
              className={classes.cursorPointer}
              onClick={() => {
                props.handleRegisterOpen(true);
              }}
            >
              <Typography color="inherit" className={classes.link}>
                Registrarse
              </Typography>
            </Grid>
          )}
        {user && <UserAvatar userContext={userContext} {...props} />}
      </Grid>
    </Hidden>
  );
};

export default withStyles(styles)(LinksTopBar);
