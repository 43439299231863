import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseIcon from '@material-ui/icons/Close';
import {
  DialogTitle,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  withStyles,
  Hidden,
  Paper,
  Typography
} from '@material-ui/core';

const styles = theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.87)',
    background: 'transparent',
    boxShadow: 'none'
  },
  flex: {
    flex: 1
  },
  body: {
    overflowY: 'auto',
    height: 'auto !important',
    maxHeight: 'calc(100vh - 50px) !important',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'calc(100vh - 50px) !important'
    }
  },
  footer: {
    padding: theme.spacing.unit * 2,
    backgroundColor: 'white',
    width: '100%',
    bottom: 0,
    zIndex: 3
  }
});
class ResponsiveDialog extends React.Component {
  render() {
    const { fullScreen, classes } = this.props;

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <Hidden mdUp>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={this.props.onClose}
                  aria-label="Close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          </Hidden>
          {this.props.dialogTitle && (
            <DialogTitle id="responsive-dialog-title" disableTypography>
              <Typography variant="h2">{this.props.dialogTitle}</Typography>
            </DialogTitle>
          )}
          <div
            className={this.props.footer ? classes.body : null}
            style={{
              maxWidth: '100%',
              overflowX: 'hidden'
            }}
          >
            {this.props.children}
          </div>
          {this.props.footer && (
            <Paper className={classes.footer}>{this.props.footer}</Paper>
          )}
        </Dialog>
      </div>
    );
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string
};

ResponsiveDialog = withMobileDialog()(ResponsiveDialog);
export default withStyles(styles)(ResponsiveDialog);
