import axios from 'axios';
import { baseUrl, refreshRoute } from './api-routes';

export const performRequest = (method, url, params, auth, user, page) => {
  const axiosInstance = axios.create();
  const body = method === 'get' ? 'params' : 'data';
  const urlParam = page ? `?page=${page}` : '';
  const config = {
    method,
    url: `${url}${urlParam}`,
    timeout: 10000,
    baseURL: baseUrl,
    [body]: params || {}
  };

  if (auth) {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
      Accept: 'application/json'
    };
  }
  // console.log('request: ', config);
  if (user !== null) {
    axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        // console.log(error);
        if (error.code === 'ECONNABORTED') {
          return Promise.reject(error);
        }

        if (error.response.status === 401) {
          // console.log('NO USER ACCESS');
          const conf = {
            method: 'get',
            url: refreshRoute,
            baseURL: baseUrl,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
            }
          };
          // console.log('Refrescando token...', conf);
          return axios
            .request(conf)
            .then(response => {
              // console.log('Refrescado con exito: ', response);
              const newUser = user;
              newUser.token = response.data.token;
              //refreshTokenSuccess(dispatch, newUser);
              const originalRequest = error.config;
              originalRequest.headers.Authorization = `Bearer ${
                response.data.token
              }`;

              // console.log('retornando original request: ', originalRequest);
              return axios(originalRequest).catch(errorSecondRequest => {
                if (
                  errorSecondRequest.response.data.status_code === 401 ||
                  errorSecondRequest.response.data.status_code === 530
                ) {
                  //resetUser(dispatch);
                } else {
                  return Promise.reject(error);
                }
              });
            })
            .catch(errorRefresh => {
              // console.log('Error al refrescar...', errorRefresh.response);
              localStorage.removeItem('jwtToken');
              //resetUser(dispatch);
              return Promise.reject(errorRefresh);
            });
        }
        return Promise.reject(error);
      }
    );
  }
  const response = axiosInstance.request(config);
  // console.log('respuesta: ', response);
  return response;
};

export default performRequest;
