import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
  Hidden,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  withWidth
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import Icon from '../common/Icon';
import UserContext from '../../context/UserContext';

import SignIn from '../pages/SignIn/SignInForm';
import Register from '../pages/Register/RegisterForm';

import MenuButton from './MenuButton';
import LinksTopBar from './LinksTopBar';
import ResponsiveDialog from '../common/ResponsiveDialog';
import { logoutUser } from '../../network/user';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flexGrow: 1
  },
  toolbar: {
    'background-color': '#FCFCFC',
    color: theme.palette.primary.textBlack,
    // textTransform: 'uppercase',
    fontSize: 16
  },
  transparentToolbar: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.contrastText,
    zIndex: '5 !important',
    fontSize: 16
  },
  logo: {
    height: '40px'
  },
  menuButton: {
    alignItems: 'center',
    color: '#767676',
    padding: theme.spacing.unit
  }
});

const links = () => {
  return [
    {
      auth: true,
      iconSlug: 'file',
      url: '/mis-reservas',
      title: 'Mis reservas'
    }
  ];
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      signInModalOpen: false,
      registerModalOpen: false,
      loggedOut: false
    };
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
  }

  toggleDrawer = open => () => {
    this.setState({
      drawerOpen: open
    });
  };

  handleSignInOpen = open => {
    this.setState({
      signInModalOpen: open
    });
  };

  handleRegisterOpen = open => {
    this.setState({
      registerModalOpen: open
    });
  };

  handleLogoutClick(userContext) {
    logoutUser(userContext.changeUser);
    this.props.history.push('/?has_logout=true');
  }

  renderMenuButton(isTransparent) {
    return (
      <MenuButton
        {...this.props}
        toggleDrawer={this.toggleDrawer}
        isTransparent={isTransparent}
      />
    );
  }

  renderLinks(userContext) {
    const hideRegister = this.props.history.pathname === '/entrar';
    console.log('ocultar registro', hideRegister);
    return (
      <LinksTopBar
        userContext={userContext}
        handleSignInOpen={this.handleSignInOpen}
        handleRegisterOpen={this.handleRegisterOpen}
        handleLogoutClick={this.handleLogoutClick}
        links={links}
        hideRegister={hideRegister}
      />
    );
  }

  renderDrawer(userContext) {
    const { user } = userContext;
    return (
      <Hidden mdUp>
        <Drawer
          anchor="top"
          open={this.state.drawerOpen}
          onClose={this.toggleDrawer(false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
            <List>
              <Link to="/">
                <ListItem>
                  <ListItemText primary="Inicio" />
                  <ListItemIcon>
                    <Icon slug="home" />
                  </ListItemIcon>
                </ListItem>
              </Link>
              {links(null).map(link => {
                if ((!user && link.auth) || (user && !link.auth)) {
                  return null;
                }
                return (
                  <Link to={link.url} key={link.url}>
                    <ListItem>
                      <ListItemText primary={link.title} />
                      <ListItemIcon>
                        <Icon slug={link.iconSlug} />
                      </ListItemIcon>
                    </ListItem>
                  </Link>
                );
              })}
              {!user && (
                <ListItem
                  onClick={() => {
                    this.handleSignInOpen(true);
                  }}
                >
                  <ListItemText primary="Iniciar sesión" />
                </ListItem>
              )}
              {!user && (
                <ListItem
                  onClick={() => {
                    this.handleRegisterOpen(true);
                  }}
                >
                  <ListItemText primary="Registrarse" />
                </ListItem>
              )}
              {user && (
                <Link to="/perfil">
                  <ListItem>
                    <ListItemText primary="Perfil" />
                    <ListItemIcon>
                      <Icon slug="user" />
                    </ListItemIcon>
                  </ListItem>
                </Link>
              )}
              {user && (
                <ListItem
                  onClick={() => {
                    this.handleLogoutClick(userContext);
                  }}
                >
                  <ListItemText primary="Salir" />
                </ListItem>
              )}
            </List>
          </div>
        </Drawer>
      </Hidden>
    );
  }

  renderSignInDialog() {
    return (
      <ResponsiveDialog
        onClose={() => {
          this.handleSignInOpen(false);
        }}
        open={this.state.signInModalOpen}
        dialogTitle="Iniciar sesión"
      >
        <SignIn
          successCallback={() => {
            this.handleSignInOpen(false);
          }}
        />
      </ResponsiveDialog>
    );
  }

  renderRegisterDialog() {
    return (
      <ResponsiveDialog
        onClose={() => {
          this.handleRegisterOpen(false);
        }}
        open={this.state.registerModalOpen}
      >
        <Register
          successCallback={() => {
            this.handleRegisterOpen(false);
          }}
          openSignIn={() => {
            this.handleRegisterOpen(false);
            this.handleSignInOpen(true);
          }}
        />
      </ResponsiveDialog>
    );
  }

  render() {
    // Hide header in payments routes
    const hideHeader = this.props.location.pathname.includes('pagos');
    if (hideHeader) {
      return null;
    }

    const { classes } = this.props;
    // If we are at the home screen this should be transparent
    let isTransparent = false;
    if (
      this.props.location.pathname === '/' &&
      (this.props.width === 'md' ||
        this.props.width === 'lg' ||
        this.props.width === 'xl')
    ) {
      isTransparent = true;
    }

    return (
      <Hidden mdDown>
        <div className={classes.root}>
          <UserContext.Consumer>
            {userContext => (
              <AppBar
                position="static"
                style={{ background: 'transparent', boxShadow: 'none' }}
              >
                <Toolbar
                  classes={{
                    root: isTransparent
                      ? classes.transparentToolbar
                      : classes.toolbar
                  }}
                >
                  {this.renderMenuButton(isTransparent)}
                  {this.renderLinks(userContext)}
                  {this.renderDrawer(userContext)}
                  {this.renderSignInDialog()}
                  {this.renderRegisterDialog()}
                </Toolbar>
              </AppBar>
            )}
          </UserContext.Consumer>
        </div>
      </Hidden>
    );
  }
}

export default withWidth()(withStyles(styles)(withRouter(Header)));
