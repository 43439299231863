import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';
// import { KeyboardArrowDown } from '@material-ui/icons';
import logo from '../../assets/iso.svg';
import whiteLogo from '../../assets/iso-white.svg';

const MenuButton = props => {
  const { classes } = props;
  let src = logo;
  if (props.isTransparent) {
    src = whiteLogo;
  }
  return (
    <Grid container spacing={24} className={classes.menuButton}>
      <Hidden only={['xs', 'sm']}>
        <Link to="/">
          <img src={src} className={classes.logo} alt="logo" />
        </Link>
      </Hidden>
      {/* <Hidden only={["md", "lg", "xl"]}>
        <Button onClick={props.toggleDrawer(true)}>
          <img src={src} className={classes.logo} alt="logo" />
          <KeyboardArrowDown color="inherit" />
        </Button>
      </Hidden> */}
    </Grid>
  );
};

export default MenuButton;
