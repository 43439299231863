import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    useNextVariants: true,
    fontFamily: ['"Ubuntu"', 'sans-serif'].join(','),
    fontSize: '14',
    h1: {
      fontFamily: 'Ubuntu',
      color: '#333333',
      fontWeight: '900',
      fontSize: '1.5rem'
    },
    h2: {
      fontFamily: 'Ubuntu',
      color: '#333333',
      fontWeight: '700',
      fontSize: '1.25rem'
    },
    h3: {
      fontFamily: 'Ubuntu',
      color: '#333333',
      fontWeight: '500',
      fontSize: '1rem'
    },
    h4: {
      fontFamily: 'Ubuntu',
      color: '#4F4F4F',
      fontWeight: '500',
      fontSize: '0.75rem'
    },
    h5: {
      fontFamily: 'Ubuntu',
      color: '#333333',
      fontWeight: '500',
      fontSize: '1rem'
    },
    h6: {
      fontFamily: 'Ubuntu',
      color: '#333333',
      fontWeight: '400',
      fontSize: '0.75rem'
    },
    subtitle1: {
      fontFamily: 'Ubuntu',
      color: '#333333',
      fontSize: '0.875rem'
    },
    body1: {
      fontFamily: 'Ubuntu',
      color: '#333333',
      fontSize: '0.875rem'
    },
    body2: {
      fontFamily: 'Ubuntu'
    },
    caption: {
      fontFamily: 'Ubuntu',
      color: '#808080',
      fontSize: '0.75rem',
      fontWeight: '300'
    }
  },
  palette: {
    primary: {
      light: '#49AF46',
      main: 'hsl(141, 100%, 27%)',
      dark: '#008c5c',
      contrastText: '#fff',
      textBlack: 'rgba(0, 0, 0, 0.87)',
      textError: 'rgba(244, 67, 54,1.0)',
      textGrey: '#828282',
      placeholderGrey: '#E2E2E7',
      terciary: '#9B031F',
      secondary: '#FF8000'
    }
  }
});
export default function ThemeProvider(props) {
  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
}
