import ReactGA from 'react-ga';
import React from 'react';
import { withRouter } from 'react-router-dom';

class GoogleAnalytics extends React.Component {
  componentWillUpdate({ location, history }) {
    const gtag = window.gtag;

    if (location.pathname === this.props.location.pathname) {
      // don't log identical link clicks (nav links likely)
      return;
    }

    if (history.action === 'PUSH') {
      ReactGA.pageview(location.pathname);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(GoogleAnalytics);
